import React, { useEffect, useState, createContext } from 'react';

import requestClient from './utils/requestClient';
import {loadCsrfToken} from './utils/api';
import EmployeeRoutes from './routers/EmployeeRoutes';
import EmployerRoutes from './routers/EmployerRoutes';
import PublicRoutes from './routers/PublicRoutes';


export const CurrentUser = createContext({ user: null, reloadUser: () => {} });

function App() {
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);

    // Function to fetch and reload the user
    const reloadUser = async () => {
        try {
            loadCsrfToken(); // Assuming this is defined elsewhere
            const response = await requestClient().get('/api/v2/user');
            setUser(response.data.data);
        } catch (error) {
            console.error('Failed to reload user', error);
        }
    };

    useEffect(() => {
        const initializeUser = async () => {
            await reloadUser();
            setLoaded(true);
        };
        initializeUser();
    }, []);

    if (!loaded) return null;

    return (!loaded ? null :
        (user ? <CurrentUser.Provider value={{ user, reloadUser }}>
            {user.user.user_type === 'employer' ? <EmployerRoutes /> : <EmployeeRoutes />}
        </CurrentUser.Provider> : <PublicRoutes />)
    );
}

export default App;

