import React from 'react';

const Pill = ({ title, className = '', size = 'sm' }) => {
    if (size === 'lg') {
        return (
            <span className={`block whitespace-nowrap px-6 py-3 bg-[#FAF9F5] rounded-full font-medium ${className}`}>{title}</span>
        );
    }

    return (
        <span className={`block whitespace-nowrap px-3 py-2 text-xs bg-[#FAF9F5] rounded-full font-medium ${className}`}>{title}</span>
    );
};

export default Pill;
