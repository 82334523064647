import React from 'react';

import Pill from './Pill';

const PillarPill = ({ pillar, className = '', size = 'sm' }) => {
    const theme = `${className} text-white bg-${pillar.theme}`
    return <Pill title={pillar.title} className={theme} size={size} />
};

export default PillarPill;
