import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import requestClient from '../../utils/requestClient';

import { OptimeLogoColor, UserAvatarIcon, CrossIcon, SettingsIcon } from '../Icons';

import { CurrentUser } from '../../App';

const NavBar = ({ links, banner, className = '' }) => {
    const {user} = useContext(CurrentUser)

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => { setMenuOpen(!menuOpen) }

    async function logout(event) {
        event.preventDefault()

        await requestClient().get('/api/v2/logout/')
            .then(response => {
                // Can't use navigate here for some reason
                window.location.href = '/'
            })
    }

    let location = useLocation();

    React.useEffect(() => {
        setMenuOpen(false)
    }, [location]);
    
    const MobileNav = () => {
        return (user ?
            <div className='xl:hidden'>
                <div className='fixed top-0 left-0 w-full h-full bg-black/70' onClick={() => toggleMenu()}></div>
                <div className={`shadow-xl z-20 fixed h-full top-0 left-0 w-[350px] bg-white`}>
                    <div className='relative text-center p-8 pt-16 border-b'>
                        <Link to="/profile" className="absolute top-[30px] left-8">
                            <SettingsIcon />
                        </Link>
                        <button className="absolute top-[33px] right-8" onClick={() => toggleMenu()}>
                            <CrossIcon className="w-[22px]" />
                        </button>
                        <UserAvatarIcon
                            alt="User Profile"
                            className="mx-auto w-full max-w-[50px]"
                        />
                        <span className="font-medium text-2xl mt-3">
                            {user.user.first_name} {user.user.last_name}
                        </span>
                    </div>
                    <div className='p-8'>
                        <ul className="font-medium flex flex-col gap-8">
                            {links.map((link, index) => (
                                <li key={index} className='text-lg'>
                                    <Link to={link.path}>{link.text}</Link>
                                </li>
                            ))}
                            <li className='text-lg'>
                                <button onClick={logout}>
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            : null)
    }

    return (user ?
        <>
            {banner ? <div className='hidden bg-purple text-white'>
                <div className='container flex lg:items-center py-4 gap-4 flex-col lg:flex-row lg:gap-8'>
                    <span className='font-bold flex-none'>Welcome to OptiMe 2.0 Beta!</span>
                    <span className='text-sm'>We’re excited to bring you OptiMe 2.0 in beta.<br />Your feedback is crucial to us, please report any issues or share your thoughts at <a href='mailto:support@optimewellbeing.com' className='font-bold'>support@optimewellbeing.com</a>.<br />Thank you for your patience and support!</span>
                </div>
            </div> : null}
            <nav className={`mx-2 my-2 xl:my-5 xl:mx-0 ${className}`}>
                <div className='container bg-white text-black py-5 px-6 rounded-full flex items-center justify-between'>
                    <Link to="/">
                        <OptimeLogoColor alt="Color OptiMe Logo" />
                    </Link>
                    <ul className="hidden font-medium xl:flex items-center gap-8">
                        {links.map((link, index) => (
                            <li key={index}>
                                <Link to={link.path}>{link.text}</Link>
                            </li>
                        ))}
                    </ul>
                    <div className="hidden xl:flex items-center">
                        <button onClick={logout} className="font-medium mr-3 leading-tight">
                            Logout
                        </button>
                        <Link to="/profile" className="">
                            <UserAvatarIcon
                                alt="User Profile"
                                className="w-full max-w-[40px]"
                            />
                        </Link>
                    </div>
                    <button className='xl:hidden' onClick={() => toggleMenu()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="14" viewBox="0 0 30 14">
                            <rect id="Rectangle_34" data-name="Rectangle 34" width="30" height="4" fill="#610e80" />
                            <rect id="Rectangle_35" data-name="Rectangle 35" width="20" height="4" transform="translate(10 10)" fill="#610e80" />
                        </svg>
                    </button>
                </div>
            </nav>
            {menuOpen ? <MobileNav /> : ''}
        </>
        : null);
}

export default NavBar;
