const Badge = ({title, theme, children}) => {
    const themeClass = `outline-${theme} bg-${theme} text-dark-${theme}`
    return (
        <div className="flex items-center"> 
            <span className={`flex items-center justify-center h-[40px] w-[40px] block rounded-full text-center text-[20px] mr-2 border border-white outline outline-2 outline-solid tracking-tight ${themeClass}`}>
                {children}
            </span>
            <span className="text-xs leading-tight max-w-[60px]">{title}</span>
        </div>
    )
}

export default Badge