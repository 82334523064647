import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

import requestClient from '../../utils/requestClient';
import { pillarMap } from '../../utils/pillarMapper';
import ArticleCardWorkshop from '../../components/Elements/Cards/ArticleCardWorkshop';
import FormSelect from '../../components/Elements/Form/FormSelect';
import Pagination from '../../components/Elements/Pagination';
import { CurrentUser } from '../../App';

const ResourceList = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate();
    const {user} = useContext(CurrentUser)
    const type = 'video'
    const [data, setData] = useState(null);
    const [selectedGoal, setSelectedGoal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const queryPillar = searchParams.get('pillar') && searchParams.get('pillar') !== '' ? searchParams.get('pillar') : 'all'
    const [pillar, setPillar] = useState(queryPillar)

    const pillars = pillarMap()

    let options = [{
        value: 'all',
        label: 'All'
    }]

    Object.keys(pillars).forEach(pillar => {
        options.push({
            value: pillar,
            label: pillars[pillar].title
        })
    })
    async function getWorkshops() {
        const response = await requestClient().get(`/api/v2/workshops/?pillar=${pillar}&page=${currentPage}`)

        if (user.user.active_goal) {
            setSelectedGoal(user.user.active_goal.goal.video.id)
        }
        setData(response.data)
    }

    useEffect(() => {
        async function getData() {
            await getWorkshops()
        }
        getData();
    }, []);

    useEffect(() => {
        async function paginate() {
            await getWorkshops()
        }
        paginate()
    }, [currentPage]);

    useEffect(() => {
        async function updatePillar() {
            await getWorkshops()
        }
        updatePillar()
    }, [pillar]);

    return (data ?
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='flex items-center justify-between gap-4 flex-col lg:flex-row border-b pb-8 mb-8'>
                        <h1>Workshops</h1>
                        <FormSelect value={pillar} options={options} onChange={(event) => setPillar(event.target.value)} />
                    </div>

                    <div className='grid gap-8 lg:grid-cols-2 mb-8'>
                        {data.data.map((article, index) => (
                            <ArticleCardWorkshop key={index} item={article} selectedGoal={selectedGoal} />
                        ))}
                    </div>
                    <Pagination currentPage={currentPage} data={data.data} pagination={data.meta.paginator} callback={setCurrentPage} />
                </div>
            </section>
        </main>
        : null);
};

export default ResourceList;