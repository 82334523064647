import React from 'react';
import { Link } from 'react-router-dom';

import { resourceUrl } from '../../../utils/resourceMapper';
import Image from '../../../components/Elements/Image';
import PillarPill from '../../../components/Elements/PillarPill';
import { pillarMapper } from '../../../utils/pillarMapper';

const ArticleCard = ({ item }) => {
    let imageUrl = 'https://place-hold.it/300x200'
    let pillar = null

    if ('thumbnail' in item && item.thumbnail) {
        imageUrl = item.thumbnail
    }
    else if ('image' in item && item.image) {
        imageUrl = item.image
    }

    if ('pillar' in item) {
        pillar = pillarMapper(item.pillar)
    }

    return (
        <div>
            <Link to={resourceUrl(item)} className="relative">
                {'pillar' in item ? (<PillarPill pillar={pillarMapper(item.pillar)} className={"absolute top-2 right-2"} />) : null}
                <Image src={imageUrl} alt={item.title} className="w-full rounded-xl aspect-video object-cover" />
                <h4 className="mt-3">{item.title}</h4>
            </Link>
        </div>
    );
};

export default ArticleCard;