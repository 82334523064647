import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import requestClient from '../../../utils/requestClient';
import { CurrentUser } from '../../../App';
import { resourceUrl } from '../../../utils/resourceMapper';
import Image from '../Image';
import Pill from '../Pill';

const WorkshopCard = () => {
    const {user} = useContext(CurrentUser)

    const [workshop, setWorkshop] = useState(null);
    const [tasks, setTasks] = useState(null);
    const [complete, setComplete] = useState(false);
    const [mode, setMode] = useState('select');
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        async function getSelfReflection() {
            if(user.user.active_goal){
                let complete = true
                
                const mappedTasks = user.user.active_goal.tasks.map(task => {
                    if(task.is_complete === false){
                        complete = false
                    }

                    return {
                        is_complete: task.is_complete,
                        title: task.task.title
                    }
                });

                setWorkshop(user.user.active_goal.goal)
                setMode('continue')
                setComplete(complete)
                setTasks(mappedTasks)
            }
            else{
                const response = await requestClient().get(`/api/v2/self-reflection?month=1`)

                if(response.data.data.length > 0){
                    const mappedTasks = response.data.data[0].workshop.goal.tasks.map(task => {
                        return {
                            is_complete: false,
                            title: task.title
                        }
                    });
    
                    setTasks(mappedTasks)
                    setWorkshop(response.data.data[0].workshop)
                    setMode('recommend')
                }
            }
            setLoaded(true)
        }
        getSelfReflection();
    }, []);

    return (loaded ?
        <div>
            <div className='flex items-center justify-between mb-2'>
                <span className='text-sm'>{mode === 'recommend' && workshop ? 'RECOMMENDED WORKSHOP' : 'YOUR WORKSHOP'}</span>
                <Pill title="50 pts" className='text-black' />
            </div>
            {workshop ? (
            <>
                <h3 className='h4 text-white mb-3'>{workshop.video.title}</h3>
                <Image src={workshop.video.image} className="w-full rounded-xl aspect-video object-cover mb-4" />
                <ul className='text-sm flex flex-col gap-2 pl-3 list-disc mb-4'>
                    {tasks.map((task, index) => (
                        <li className={`${task.is_complete ? 'line-through' : ''}`} key={index}>
                            {task.title}
                        </li>
                    ))}
                </ul> 
                {!complete ?
                <Link to={resourceUrl(workshop.video)} className="btn btn-invert block bg-white border-none">{mode === 'recommend' ? 'View' : 'Continue'}</Link> :
                <Link to={'/workshops'} className="btn btn-invert block bg-white border-none uppercase">Browse Workshops</Link>
                }
                {/* {mode === 'recommend' && (<Link to={'/workshops'} className="btn btn-invert block bg-white border-none uppercase">SELECT WORKSHOP</Link>)} */}
            </>) : 
                <>
                    <p className='mb-4'>Select a workshop to complete this month and earn 50 pts.</p>
                    <Link to={'/workshops'} className="btn btn-invert block bg-white border-none uppercase">Browse Workshops</Link>
                </>
            }
        </div>
    : null);
};

export default WorkshopCard;