import React, {useContext} from 'react';

import { CurrentUser } from '../../App';
import Badge from '../Elements/Badge';
import { UserIcon, LightBulbIcon } from '@heroicons/react/24/outline';

const ScoreBar = ({ className = "" }) => {
    const {user} = useContext(CurrentUser)

    const now = new Date()

    const greeting = () => {
        const date = new Date(user.current_time)

        if (date.getHours() < 12){
            return  "Good morning"
        }
        else if(date.getHours() < 18){
            return "Good afternoon"
        }

        return  "Good evening"
    }

    const iconColour = (logins) => {
        if(logins === 2){
            return "silver"
        }

        if(logins > 2){
            return "gold"
        }

        return "bronze" 
    }

    const userMessage = (user) => {
        // Month completed
        if(user.user.month_completed) {
            return (<span><strong>Amazing work!</strong> You’ve completed everything for this month.<br/>
            Your reward is ready to claim, go ahead and enjoy the benefits of your effort!
            </span>)
        }

        // Logins complete but not workshop
        if(user.user.completed_three_logins_this_month && !user.user.other_goal) {
            return (<span>Great progress on completing your logins!<br />
            Just one final step, complete a workshop to unlock your reward!
            </span>)
        }

        // Workshop complete but not logins
        if(user.user.other_goal && !user.user.completed_three_logins_this_month) {
            return (<span>Fantastic effort on the workshop!<br />
            Login 3 times this month to unlock your reward!
            </span>)
        }
        
        // Nothing complete
        return (<span>Welcome back, you’re off to a great start this month!<br />
        Just login to the platform 3 times and complete a workshop to unlock your reward!
        </span>)
    }

    return ( user ?
        <div className={`${className} `}>
            <div className='text-purple container pt-4 pb-8 flex items-center flex-col lg:flex-row gap-3'>
                <div className='flex-1 text-center lg:text-left'>
                    <span className='h1 block'>
                        {greeting()}, {user.user.first_name}
                    </span>
                    <p className='mt-1'>{userMessage(user)}</p>
                </div>
                <div className='flex-1 flex items-center flex-col lg:flex-row gap-3 lg:justify-end'>
                    <div className='flex items-center'>
                        <span className="text-[35px] font-bold mr-1">{user.user.points}</span>
                        <span className="text-sm">PTS</span>
                    </div>
                    <div className="flex items-center bg-white rounded-full px-4 py-4 gap-8">
                        {user.user.other_goal && (<Badge title="Workshop Complete" theme="gold"><LightBulbIcon className={`h-[25px] w-[25px]`} /></Badge>)}
                        <Badge title="Monthly Logins" theme={iconColour(user.stats.logins)}>{user.stats.logins >= 3 ? '3+' : user.stats.logins}</Badge>
                    </div>
                </div>
            </div>
        </div>
    : null);
};

export default ScoreBar;
