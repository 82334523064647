import React from 'react';
import { Link } from 'react-router-dom';
import { resourceMapper } from '../../utils/resourceMapper';

const ArticleBreadcrumb = ({ article }) => {
    return (
        <nav className='hidden lg:block'>
            <ul className='text-sm uppercase flex items-center'>
                <li>
                    <Link to="/resources">Resources</Link>
                    <span className='mx-2'>/</span>
                </li>
                <li>
                    <Link to={`/resources/${article.content_type}`}>
                        {resourceMapper(article.content_type).title}
                    </Link>
                    <span className='mx-2'>/</span>
                </li>
                <li className='text-purple font-bold'>
                    {article.title}
                </li>
            </ul>
        </nav>
    );
};

export default ArticleBreadcrumb;
