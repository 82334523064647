import React, { useContext } from 'react';

import {
    Routes, Route, Navigate
} from 'react-router-dom';

import { CurrentUser } from '../App';

import MainLayout from '../components/Layout/MainLayout';
import BaseLayout from '../components/Layout/BaseLayout';
import Dashboard from '../pages/Dashboard';
import HelpAndSupport from '../pages/HelpAndSupport/';
import OnboardingFlow from '../pages/Onboarding/Flow';
import Profile from '../pages/Profile';
import QuestionnaireFlow from '../pages/Questionnaire/Flow';
import Favourites from '../pages/Favourites';
import Rewards from '../pages/Rewards';
import ResourcesFlow from '../pages/Resources/Flow';
import PrivacyPolicy from '../pages/General/PrivacyPolicy';
import CookiePolicy from '../pages/General/CookiePolicy';
import Terms from '../pages/General/Terms';
import Workshops from '../pages/Workshops/';
import Newsletter from '../pages/Resources/Newsletter'; 

const EmployeeRoutes = () => {
    const {user} = useContext(CurrentUser)

    const links = [
        { path: "/dashboard", text: "Home" },
        { path: "/workshops", text: "Workshops" },
        { path: "/resources", text: "Resources" },
        { path: "/favourites", text: "Favourites" },
        { path: "/rewards", text: "Rewards" },
        { path: "/support", text: "Help and Support" }
    ];

    return (
        <Routes>
            <Route path="/" element={<Navigate to={user.user.completed_onboarding ? '/dashboard' : '/onboarding'} replace />} />
            <Route path="/" element={<BaseLayout links={links} />}>
                <Route path="onboarding/*" element={<OnboardingFlow />} />
                <Route path="onboarding" element={<Navigate to="/onboarding/step-1" replace />} />
                <Route path="questionnaire/*" element={<QuestionnaireFlow />} />
            </Route>
            <Route path="/" element={<MainLayout links={links} banner={true} />}>
                <Route path="dash" element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="home" element={<Dashboard />} />
            </Route>
            <Route path="/" element={<MainLayout links={links} />}>
                <Route path="workshops" element={<Workshops />} />
                <Route path="profile" element={<Profile />} />
                <Route path="rewards" element={<Rewards />} />
                <Route path="newsletter/onsite/:id"  element={<Newsletter />} />
                <Route path="resources/*" element={<ResourcesFlow />} />
                <Route path="favourites" element={<Favourites />} />
                <Route path="support" element={<HelpAndSupport />} />
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="cookies" element={<CookiePolicy />} />
                <Route path="terms" element={<Terms />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default EmployeeRoutes;